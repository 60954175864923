<template>
  <div class="echart">
    <el-row>
      <el-col :span="20">
        <el-form :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="统计店铺" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shopId"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入店铺名称"
              class="w160 mr20"
            >
              <el-option
                v-for="item in options.shopId"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="时间筛选">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w300"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="changeTime"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="门店or门诊" v-if="!userInfo.shop_id">
            <el-radio-group v-model="search.isShop">
              <el-radio label="1">门店</el-radio>
              <el-radio label="2">门诊</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-col>

      <el-col :span="4" style="display: flex;justify-content: flex-end;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="dataBox mt20 boxBottom">
      <div v-for="(item, index) in dataReveal.revealList" :key="index" class="dataReveal">
        <!-- <img :src="item.icon" alt /> -->
        <div class="reveal">
          <div class="revealText">
            {{ item.name }}&nbsp
            <el-tooltip placement="top">
              <div slot="content">{{ item.content }}</div>
              <span class="iconfont">&#xe67f;</span>
            </el-tooltip>
          </div>
          <div class="revealNum">{{ item.num }}</div>
        </div>
      </div>
    </div>

    <!-- echarts -- start -->
    <div class="dataBox mt20 boxBottom" style="height: 360px;">

      <div class="echartsBox" id="jd_echart" ref="chart1"></div>
      <div class="echartsBox" id="cj_echart" ref="chart2"></div>
    </div>

    <!-- echarts -- end -->

    <div class="dataBox mt30 pt20 pb20">
      <div
        v-for="(item, index) in dataReveal.revealList1"
        :key="index"
        class="rapid"
        @click="tapBtn(item.link)"
      >
        <div class="revealText">{{ item.name }}</div>
      </div>
    </div>
    <div class="modules client">
      <div class="modulesTel">客户统计</div>
      <div class="modulesCon">
        <div
          v-for="(item, index) in dataReveal.revealList2"
          :key="index"
          @click="tapBtn(item.link)"
          :class="item.link?'cursor':'' "
          class="mb20 itemCon"
        >
          <div class="itemConNum">{{ item.num }}</div>
          <div>
            <img :src="item.icon" alt />
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="modules">
      <div class="modulesTel">员工业绩统计</div>
      <div class="modulesCon">
        <el-table
          :data="dataReveal.revealList3"
          border
          size="small"
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
          style="width: 100%"
        >
          <el-table-column prop="name" label="员工姓名" />
          <el-table-column prop="newOrder" label="新单业绩" />
          <el-table-column prop="repairOrder" label="补单业绩" />
          <el-table-column prop="experience" label="体验成交" />
          <el-table-column prop="referral" label="转介绍业绩" />
          <el-table-column prop="serviceLog" label="护理量" />
          <el-table-column prop="waiLianNums" label="外联人数" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {
  readAdminSysHomeInfo,
  readAdminSysHomePersonAchieve,
  get_number_of_prompts
} from "@/api/sys";
import { getShopList } from "@/api/shop";
import { mapGetters } from "vuex";
import { setStore, getStore } from "@/utils/store";
class DataReveal {
  revealList = [
    {
      name: "当前业绩(元)",
      icon: require("@/assets/images/index_1.png"),
      num: 0
    },
    {
      name: "完成新单",
      icon: require("@/assets/images/index_2.png"),
      num: 0
    },
    {
      name: "完成补单",
      icon: require("@/assets/images/index_3.png"),
      num: 0
    },
    {
      name: "新单成交率(%)",
      icon: require("@/assets/images/index_4.png"),
      num: 0
    },
    {
      name: "成交个数",
      icon: require("@/assets/images/index_5.png"),
      num: 0
    },
    {
      name: "完成进店",
      icon: require("@/assets/images/index_6.png"),
      num: 0
    },
    {
      name: "网络进店率(%)",
      icon: require("@/assets/images/index_7.png"),
      num: 0
    },
    {
      name: "转介绍个数",
      icon: require("@/assets/images/index_8.png"),
      num: 0
    }
  ];

  revealList1 = [
    {
      name: "会员列表",
      icon: "",
      link: "客户列表"
    },
    {
      name: "门店信息",
      icon: "",
      link: "店铺列表"
    },
    {
      name: "订单模块",
      icon: "",
      link: "销售出库单"
    },
    {
      name: "数据分析",
      icon: "",
      link: "门店线上导客"
    },
    {
      name: "商品列表",
      icon: "",
      link: "商品列表"
    },
    {
      name: "员工列表",
      icon: "",
      link: "员工列表"
    },
    {
      name: "优秀案例",
      icon: "",
      link: "案例列表"
    },
    {
      name: "回访列表",
      icon: "",
      link: "客保列表"
    }
  ];
  revealList2 = [
    {
      name: "成交客户数",
      icon: require("@/assets/images/info_1.png"),
      link: "新单列表",
      num: 0
    },
    {
      name: "保有客户数",
      icon: require("@/assets/images/info_6.png"),
      link: "保有顾客",
      num: 0
    },
    {
      name: "流失客户数",
      icon: require("@/assets/images/info_2.png"),
      link: "流失顾客",
      num: 0
    },
    {
      name: "流失预警数",
      icon: require("@/assets/images/info_3.png"),
      link: "流失预警",
      num: 0
    },
    {
      name: "保有率",
      icon: require("@/assets/images/info_5.png"),
      link: "",
      num: 0
    },
    {
      name: "当月预警回流率",
      icon: require("@/assets/images/info_5.png"),
      link: "业务分析报表",
      num: 0
    },

    // {
    //   name: "回流率",
    //   icon: require("@/assets/images/info_4.png"),
    //   link: "",
    //   num: 0
    // }
  ];
  revealList3 = [];
}
class Search {
  shopId = ""; // 统计范围
  startTime = ""; // 开始日期
  endTime = ""; // 结束日期
  isShop = ""; // 门店or门诊
}
class Options {
  constructor() {
    this.shopId = []; // 店铺
  }
}
export default {
  name: "echart",
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      dataReveal: new DataReveal(), // 数据展示
      value1: "", //时间范围
      isShop: false,
      shop: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.search.shopId = this.userInfo.shop_id;
    this.shop = this.userInfo.shop_id ? true : false;
    if(this.userInfo.shop_id) {
      this.search.isShop = "";
    }else{
      this.search.isShop = "1";
      if(this.userInfo.is_md== false){
          this.search.isShop = "2";
        }
    }
    this.getList();
    if (getStore("prompt") == 0) {
      this.getOptions();
      setStore("prompt", 1);
    }
    // this.echarts1();
    // this.echarts2();
  },
  methods: {
    // 获取选项
    getOptions() {
      get_number_of_prompts().then(res => {
        let that = this;
        res.data.list.forEach(item => {
          if (item.num > 0) {
            that.$notify({
              title: "警告",
              message: item.describe,
              duration: 0,
              type: "warning",
              onClick() {
                that.tiaozhuan(item.type);
              }
            });
          }
        });
      });
    },
    // 提示title
    tiaozhuan(type) {
      // console.log(type)
      if (type == 1) {
        this.tapBtn("预警列表");
      } else if (type == 2) {
        this.tapBtn("追责列表");
      } else if (type == 3) {
        this.tapBtn("客保列表");
      }
    },
    // 店铺查找
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shopId = res.list;
      });
    },
    // 获取数据
    getList(option) {
      readAdminSysHomeInfo({ ...this.search }).then(res => {

        this.echarts1(res.sysFinishEnterStoreEcharts);
        this.echarts2(res.sysDealNumberEcharts)
        // let cominInData = res.sysFinishEnterStoreEcharts;



        this.dataReveal.revealList[0].num = res.sysCurrentPerformance;
        this.dataReveal.revealList[1].num = res.sysCompleteNewOrder;
        this.dataReveal.revealList[2].num = res.sysCompleteSupplement;
        this.dataReveal.revealList[3].num = res.sysNewOrderTurnoverRate;
        this.dataReveal.revealList[4].num = res.sysDealNumber;
        this.dataReveal.revealList[5].num = res.sysFinishEnterStore;
        this.dataReveal.revealList[6].num = res.sysOnlineStoreEntryRate;
        this.dataReveal.revealList[7].num = res.referralsCout;

        this.dataReveal.revealList2[0].num = res.customerCJNumber;   /*  成交客户*/
        this.dataReveal.revealList2[1].num = res.customerTenureNumber;  /*  保有客户*/
        this.dataReveal.revealList2[2].num = res.customerDrainNumber;  /*  流失客户*/
        this.dataReveal.revealList2[3].num = res.customerWarnNumber;  /*  预警客户*/
        this.dataReveal.revealList2[4].num = res.customerRetentionRate;   /*  保有率*/
        this.dataReveal.revealList2[5].num = res.back_warn_Rate;  /*  回流率*/

        this.dataReveal.revealList[0].content = '订单完成时间在统计时间内，店面产生的所有业绩合计';
        this.dataReveal.revealList[1].content = '业务日期在统计时间内，所有订单类型为新单的收款合计(收款单合计减去退款单合计)';
        this.dataReveal.revealList[2].content = '业务日期在统计时间内，所有订单类型为补单的收款合计(收款单合计减去退款单合计)';
        this.dataReveal.revealList[3].content = '统计时间内，成交顾客人数占总进店人数的比率';
        this.dataReveal.revealList[4].content = '统计时间内，进店且首次成交500以上的新单人数统计';
        this.dataReveal.revealList[5].content = '首次进店或者二次进店在统计时间内的人数统计';
        this.dataReveal.revealList[6].content = '线上进店人数占线上档案人数的比率';
        this.dataReveal.revealList[7].content = '来源是转介绍且进店的顾客人数';
      });
      readAdminSysHomePersonAchieve({ ...this.search }).then(res => {
        this.dataReveal.revealList3 = res.list;
      });
    },
    // 时间筛选
    changeTime(val) {
      if (val) {
        this.search.startTime = val[0];
        this.search.endTime = val[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
        this.getList("search");
      }
    },
    // 快捷导航
    tapBtn(routerName) {
      this.$router.push({ name: routerName });
    },

    echarts1(echartsData){
      var myChart = echarts.init(this.$refs.chart1);
      var option;

      var datas = [echartsData];

      option = {
        title: {
          text: '进店人数统计',
          left: 'center',
          textStyle: {
            color: '#999',
            fontWeight: 'normal',
            fontSize: 14
          }
        },
        series: datas.map(function (data, idx) {
          var top = idx * 33.3;
          return {
            type: 'pie',
            radius: [30, 70],
            top: top + '%',
            height: '80%',
            left: 'center',
            width: 400,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} 人}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#999'
                }
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: data
          };
        })
      };

      option && myChart.setOption(option);

    },

    // echarts 2-------------------------
    echarts2(echartsData){
      console.log('2233114466');
      var myChart = echarts.init(this.$refs.chart2);
      var option;

      var datas = [echartsData];

      option = {
        title: {
          text: '成交人数统计',
          left: 'center',
          textStyle: {
            color: '#999',
            fontWeight: 'normal',
            fontSize: 14
          }
        },
        series: datas.map(function (data, idx) {
          var top = idx * 33.3;
          return {
            type: 'pie',
            radius: [30, 70],
            top: top + '%',
            height: '80%',
            left: 'center',
            width: 400,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} 人}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#999'
                }
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: data
          };
        })
      };

      option && myChart.setOption(option);


    }

  }
};
</script>

<style lang='scss' scoped>
.search {
  @include successBtn(70px);
}

.mainColor {
  color: #2dc0a3;
}
.el-form-item {
  margin-bottom: 0;
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 160px;
  }
}
.warn {
  padding-right: 30px;
  height: 34px;
  margin-left: 50px;
  margin-right: 20px;
}
.boxBottom {
  border-bottom: 6px solid #f6f6f6;
}
.dataBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .dataReveal {
    width: 25%;
    padding: 24px 0px;
    display: flex;
    text-align: center;
    img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
    .reveal {
      border-right: 1px solid #ddd;
      width: 100%;
      .revealText {
        font-size: 14px;
        color: #999;
      }
      .revealNum {
        text-align: center;
        color: #665f59;
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
  .dataReveal:nth-child(4n) .reveal {
    border-right: 0;
  }
  .rapid {
    width: 10%;
    padding: 14px 20px;
    display: flex;
    text-align: center;
    background: #eee;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 4px;
    margin: 0 60px 20px 60px;
  }
  .rapid:hover {
    box-shadow: 0px 0px 3px #bbb;
  }
  .rapid:nth-child(1) {
    color: #cc663e;
    // background: #ebbcaa;
    background: linear-gradient(to bottom right, #ebbcaa, #ebbcaa70);
  }
  .rapid:nth-child(2) {
    // background: #b9b3d9;
    color: #7265bb;
    background: linear-gradient(to bottom right, #b9b3d9, #b9b3d970);
  }
  .rapid:nth-child(3) {
    // background: #f6dfad;
    color: #ddb153;
    background: linear-gradient(to bottom right, #f6dfad, #f6dfad70);
  }
  .rapid:nth-child(4) {
    // background: #c3e1d5;
    color: #51c999;
    background: linear-gradient(to bottom right, #c3e1d5, #c3e1d570);
  }
  .rapid:nth-child(5) {
    // background: #f0cceb;
    color: #e77ad9;
    background: linear-gradient(to bottom right, #f0cceb, #f0cceb70);
  }
  .rapid:nth-child(6) {
    // background: #dae0be;
    color: #a0b34f;
    background: linear-gradient(to bottom right, #dae0be, #dae0be70);
  }
  .rapid:nth-child(7) {
    // background: #b0ebc6;
    color: #6bb988;
    background: linear-gradient(to bottom right, #b0ebc6, #b0ebc670);
  }
  .rapid:nth-child(8) {
    // background: #CEDEF7;
    color: rgb(134, 164, 212);
    background: linear-gradient(to bottom right, #cedef7, #cedef770);
  }
  .revealText {
    width: 100%;
  }
}

.modules {
  background: #fff;
  margin-top: 10px;
  .modulesTel {
    padding: 6px 0px 20px;
    color: #665f59;
    font-size: 14px;
    font-weight: bold;
  }
  .modulesCon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .itemCon {
      width: 30%;
      padding: 10px;
      font-size: 14px;
      color: #878683;
      div {
        text-align: center;
      }
      .itemConNum {
        color: #665f59;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-left: 20px;
      }
      img {
        width: 18px;
        height: 18px;
        vertical-align: bottom;
        margin-right: 6px;
      }
      span {
        color: #f15956;
      }
    }
    .cursor {
      cursor: pointer;
    }
  }
}
.modules:last-child {
  margin-bottom: 0;
}
.client {
  padding: 10px 0;
  border-top: 6px solid #f6f6f6;
  border-bottom: 6px solid #f6f6f6;
  // border-radius: 6px;
}
::v-deep .el-notification__group {
  cursor: pointer !important;
}
.echartsBox{
  width: 50%;
  height: 360px;
  float: left;
}
</style>
